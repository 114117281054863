import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d540c930"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-navigation p-8 border-r" }
const _hoisted_2 = { class: "main-navigation__list text-left" }
const _hoisted_3 = { class: "text-lg main-navigation__list__label" }
const _hoisted_4 = { class: "main-navigation__sublist" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (menuItem) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "main-navigation__list__item",
          key: menuItem.id
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(menuItem.label), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.submenu, (submenuItem) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "main-navigation__sublist__item font-bold",
                key: submenuItem.id
              }))
            }), 128)),
            _createVNode(_component_v_expansion_panels, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.submenu, (submenuItem) => {
                  return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                    key: submenuItem.id,
                    class: "min-h-1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(submenuItem.label), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_expansion_panel_text, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(submenuItem.category.data.attributes.articles.data, (article) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "main-navigation__sublist__link main-navigation__sublist__nestedlink ml-1 font-normal level-2",
                              key: article.id
                            }, [
                              _createVNode(_component_router_link, {
                                to: 
                      '/' +
                      menuItem.category.data.attributes.slug +
                      '/' +
                      submenuItem.category.data.attributes.slug +
                      '/' +
                      article.attributes.slug
                    
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(article.attributes.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 2
            }, 1024)
          ])
        ]))
      }), 128))
    ])
  ]))
}