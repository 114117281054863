import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "default-layout flex-col" }
const _hoisted_2 = { class: "top-bar-area" }
const _hoisted_3 = { class: "main-area flex" }
const _hoisted_4 = { class: "main-navigation-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_MainNavigation = _resolveComponent("MainNavigation")!
  const _component_BlogPost = _resolveComponent("BlogPost")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TopBar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MainNavigation)
      ]),
      _createVNode(_component_BlogPost)
    ])
  ]))
}