import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  { path: "/", component: HomeView },
  {
    path: "/:category/:subcategory/:slug",
    name: "ArticleDetail",
    component: () => import("../views/ArticleDetail.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
