
import { Options, Vue } from "vue-class-component";
import TopBar from "@/components/top-bar.vue";
import MainNavigation from "@/components/main-navigation.vue";
import PageFooter from "@/components/page-footer.vue";
import BlogList from "@/components/blog-list.vue";

@Options({
  components: {
    TopBar,
    MainNavigation,
    PageFooter,
    BlogList,
  },
})
export default class HomeView extends Vue {}
