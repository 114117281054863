
import { defineComponent } from "vue";

import qs from "qs";
import axios from "axios";

declare let process: {
  env: {
    VUE_APP_ROOT_API: string;
  };
};

interface ArticlesList {
  data: Article[];
  id: number;
}
interface Article {
  attributes: {
    content: string;
    title: string;
  };
  id: number;
}
interface MenuItem {
  attributes: {
    articles?: ArticlesList[];
    slug: string;
    title: string;
  };
  id: number;
}
interface MainNavigation {
  id: string;
  label: string;
  category: MenuItem;
  submenu: [
    {
      id: string;
      label: string;
      category: {
        data: MenuItem;
      };
    }
  ];
}

export default defineComponent({
  data() {
    return {
      menu: [] as MainNavigation[],
      loadingResult: true,
    };
  },
  async mounted() {
    this.loadingResult = true;
    const query = qs.stringify(
      {
        fields: ["title"],
        populate: {
          menu: {
            fields: ["label"],
            populate: {
              category: {
                fields: ["label", "slug"],
              },
              submenu: {
                fields: ["label"],
                populate: {
                  category: {
                    fields: ["label", "slug"],
                    populate: {
                      articles: {
                        fields: ["slug", "title"],
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    axios
      .get(`${process.env.VUE_APP_ROOT_API}/main-menu?${query}`)
      .then((response) => {
        console.log(response);
        this.menu = response.data.data.attributes.menu;
        console.log(this.menu);
        this.loadingResult = false;
      })
      .catch((error) => console.log(error));

    /*fetch(
      "http://localhost:1337/api/main-menu?populate[0]=menu&populate[1]=menu.category&populate[2]=menu.submenu&populate[3]=menu.submenu.category&populate[4]=menu.submenu.category.articles.attributes",
      { headers: { Authentication: "Bearer" + process.env.API_TOKEN_SALT } }
    )
      .then((res) => res.json())
      .then((data) => {
        this.menu = data.data.attributes.menu;
        console.log(data);
        this.loadingResult = false;
      });*/
  },
});
