
import { defineComponent } from "vue";

declare let process: {
  env: {
    API_TOKEN_SALT: string;
    VUE_APP_TITLE(VUE_APP_TITLE: any): unknown;
    VUE_APP_ROOT_API: string;
  };
};
interface BlogList {
  data: [{ id: string }];
}

export default defineComponent({
  data() {
    return {
      articles: {} as BlogList,
      loadingResult: true,
      imageLink: `${process.env.VUE_APP_ROOT_API}/articles/`,
    };
  },
  mounted() {
    this.loadingResult = true;
    fetch(`${process.env.VUE_APP_ROOT_API}/articles`, {
      headers: { Authentication: "Bearer" + process.env.API_TOKEN_SALT },
    })
      .then((res) => res.json())
      .then((data) => {
        this.articles = data;
        console.log(data);
        this.loadingResult = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
});
